.card {
    width: 16rem;

}

.card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0, 0,0, 0.253) !important;
}

.card-text {
    font-size: 0.9rem;
    padding: 0.4rem;
}

.container-fluid .row {
    padding-top: 3rem;
}