body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex:1 1;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.card {
    width: 16rem;

}

.card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0, 0,0, 0.253) !important;
}

.card-text {
    font-size: 0.9rem;
    padding: 0.4rem;
}

.container-fluid .row {
    padding-top: 3rem;
}
.headerstyle {
    text-align: center;
    font-family: Neutraface Display,script;
    padding-top: 10px;
    text-transform: uppercase;    
    font-size: 2.5em;
}

hr {
    color: linear-gradient(90deg,#ffc029,#4CD8AF);
    background-color: #ffc029;
    width: 100px;
    margin: auto;
}

.logo {
    padding-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.logo-sawarthia {
    
    display: block;
    margin-left: auto;
    margin-right: auto;
    transition: all .2s ease-in-out;
    
}

.logo-sawarthia:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.example {
    background-color: #ffc029;
    background-image: linear-gradient(90deg,#ffc029,#4CD8AF);
    bottom: 0;
    content: "";
    display: block;
    height: .1875rem;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 100px;
}
