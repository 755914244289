.headerstyle {
    text-align: center;
    font-family: Neutraface Display,script;
    padding-top: 10px;
    text-transform: uppercase;    
    font-size: 2.5em;
}

hr {
    color: linear-gradient(90deg,#ffc029,#4CD8AF);
    background-color: #ffc029;
    width: 100px;
    margin: auto;
}

.logo {
    padding-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.logo-sawarthia {
    
    display: block;
    margin-left: auto;
    margin-right: auto;
    transition: all .2s ease-in-out;
    
}

.logo-sawarthia:hover{
    transform: scale(1.05);
}

.example {
    background-color: #ffc029;
    background-image: linear-gradient(90deg,#ffc029,#4CD8AF);
    bottom: 0;
    content: "";
    display: block;
    height: .1875rem;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 100px;
}